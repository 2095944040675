import { device } from "src/styles/breakpoints"
import { graphiteOne } from "src/styles/newColors"
import styled from "styled-components"

const HeroInterOneDesktop = "https://central-imagens.bancointer.com.br/images-without-small-versions/one-hero-web/image.webp"
const HeroInterOneMobile = "https://central-imagens.bancointer.com.br/images-without-small-versions/one-hero-mobile/image.webp"

export const SectionVoceNaFrente = styled.section`
  min-height: 640px;
  background: ${graphiteOne} url(${HeroInterOneMobile}) no-repeat top center;
  background-size: cover;
  align-items: end;
  display: flex;
  position: relative;
  padding: 40px 0;

  @media ${device.tablet} {
    min-height: 516px;
    background: ${graphiteOne} url(${HeroInterOneDesktop}) no-repeat top center;
    background-size: cover;
    align-items: center;
  }
  @media ${device.desktopLG} {
    min-height: 576px;
  }
  @media ${device.desktopXL} {
    min-height: calc(100vh - 73px);
  }

  button {
    max-width: 100%;
  }
`
