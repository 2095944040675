import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_MD } from 'src/styles/breakpoints'

import WebmContainerVideo from './webm-container-video/_index'

import CharlesAlexandre from './../../assets/images/charles-alexandre.png'
import TerezinhaNeila from './../../assets/images/terezinha-neila.png'
import TalissonSilva from './../../assets/images/thalisson-silva.png'

import { BoxGrayClient, SectionPorQuemMaisEntende } from './styles'

const InterOnePorQuemMaisEntende = ({ setOpenModal, setDobra }: InterOneProps) => {
  const width = useWidth(300)
  const [ sendDatalayerEvent ] = useDataLayer()

  const videoMobile = "https://central-imagens.bancointer.com.br/gif/one-dobra5-gifmobile/arquivo.webm"
  const videoSmallDesktop = "https://central-imagens.bancointer.com.br/gif/one-dobra5-gif360/arquivo.webm"
  const urlVideo = 'https://www.youtube.com/embed/kK8WWvu1H4g?si=XlHJIx-ge7fNNpRN?&autoplay=1'

  const clientList = [
    {
      description: "\"Antigamente, quando eu era cliente de outros bancos, era uma peleja! Hoje, envio um e-mail para a minha advisor e ela resolve rapidamente. A urgência em que ela trata as coisas, é nota 1000!\"",
      image: TerezinhaNeila,
      name: "Terezinha Neila",
    },
    {
      description: "\"A facilidade de conversar com meu advisor é incrível! Eu queria muito investir um dinheiro, mas, não sabia por onde começar. Segui as recomendações do meu advisor e finalmente, criei minha carteira.\"",
      image: CharlesAlexandre,
      name: "Charles Alexandre",
    },
    {
      description: "\"Com a possibilidade de ter um advisor, eu trouxe meus investimentos de outros bancos – e me tornei um Inter One. É bom demais ter alguém de fácil acesso pra te auxiliar, de forma leve e fácil.\"",
      image: TalissonSilva,
      name: "Talisson Silva",
    },
  ]

  return (
    <SectionPorQuemMaisEntende
      className='d-flex align-items-center position-relative px-0'
      id='inter-one-por-quem-mais-entende'
    >
      {
        width >= WIDTH_MD && (
          <WebmContainerVideo video={videoSmallDesktop} urlVideo={urlVideo} />
        )
      }

      <div className='container px-0'>
        <div className='row'>
          {
            width < WIDTH_MD && (
              <WebmContainerVideo video={videoMobile} urlVideo={urlVideo} />
            )
          }
          <div className='col-12 col-md-6 px-4 pl-xl-3'>
            <h2 className='font-citrina text-graphite fs-24 lh-28 fs-md-28 lh-md-34 fs-lg-40 lh-lg-44 fs-xl-48 lh-xl-52 fw-600 my-4 my-lg-5'>
              <span className='d-xl-block'>Inter One por quem</span> <span className='d-xl-block'>mais entende do</span> assunto: nossos clientes!
            </h2>
            {clientList.map((items: ClientlistProps, index: number) => (
              <BoxGrayClient key={index}>
                <p className='col-12 px-0 fs-14 lh-17 fs-lg-16 lh-lg-20 text-graphite'>
                  {items.description}
                </p>
                <div className='d-flex align-items-center justify-content-start'>
                  <img
                    src={items.image}
                    title={items.description}
                    alt={items.description}
                    width={32}
                    height={32}
                  />
                  <span className='fs-12 lh-14 fs-lg-14 lh-lg-17 text-graphite fw-600 ml-2'>{items.name}</span>
                </div>
              </BoxGrayClient>
            ))}
            <button
              title='Quero ser Inter One'
              className='btn btn--lg bg-orange--base text-white text-none mb-md-4'
              onClick={() => {
                setOpenModal && setOpenModal(true)
                setDobra('dobra_05')
                sendDatalayerEvent({
                  section: 'dobra_05',
                  section_name: 'Inter One você na frente',
                  element_action: 'click button',
                  element_name: 'Quero ser Inter One',
                })
              }}
            >
              Quero ser Inter One
            </button>
          </div>
        </div>
      </div>
    </SectionPorQuemMaisEntende>
  )
}

export default InterOnePorQuemMaisEntende
