import React, { Fragment, useState } from 'react'
import IconsSwitch from 'src/components/IconsSwitch/_index'
import ImageWebp from 'src/components/ImageWebp'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import * as S from './style'
import { ItemsProps, dataProps, infoDataProps } from './types'

const ToggleAccordeonImage = ({ infoData, section, sectionName }: infoDataProps) => {
  const data = infoData
  const [ sendDatalayerEvent ] = useDataLayer()
  const [ opened, setOpened ] = useState([ true ])

  function toggleAccordeon (index: number) {
    const newOpenedState = new Array(3).fill(false)
    newOpenedState[index] = true

    setOpened([ ...newOpenedState ])

    if (opened[index] && newOpenedState[index] !== opened[index]) {
      setOpened([ false ])
    }
  }

  return (
    <>
      {
        data.infos.map((item: dataProps, index: number) => (
          <Fragment key={item.id}>
            <S.ImageAccordion
              className={`col-12 col-md-6 text-center ${opened[index] ? 'd-block' : 'd-none'}`}
            >
              <ImageWebp
                pathSrc={item.image}
                altDescription={item.altImage}
                arrayNumbers={[ 310, 336, 417, 552, 552 ]}
                arrayNumbersHeight={[ 310, 336, 417, 552, 552 ]}
                className='mx-auto'
              />
            </S.ImageAccordion>
            <div className='col-12 col-md-6 offset-md-6 mt-lg-2 mt-xl-3'>
              <S.Accordeon className={`${opened[index] ? 'active' : 'cursor-pointer'}`}>
                <S.AccordeonTitle
                  onClick={() => {
                    toggleAccordeon(index)
                    sendDatalayerEvent({
                      section: section,
                      section_name: sectionName,
                      element_action: 'click button',
                      element_name: item.title,
                    })
                  }}
                >
                  <div className='d-flex justify-content-center align-items-center'>
                    <div className='mr-3'>
                      <IconsSwitch
                        icon={item.iconTitle} color='#616161'
                        size='MD' lib='interco'
                        customPath={item.customPath}
                      />
                    </div>
                    <h3
                      title={item.title}
                      className='fs-14 lh-17 fs-lg-18 lh-lg-22 text-graphite fw-600 mb-0'
                      dangerouslySetInnerHTML={{ __html: item.title }}
                    />
                  </div>
                  <div className={`${opened[index] ? 'arrow-active' : ''} d-flex justify-content-center align-items-center`}>
                    <IconsSwitch icon='chevron-down' color='#616161' size='SM' />
                  </div>
                </S.AccordeonTitle>
                <hr style={{ borderTop: '1px dashed #DEDFE4' }} className='w-100 mb-lg-3' />
                <S.AccordeonContent className={`col-12 px-0 ${item.id === 0 ? 'd-block' : ''}`}>
                  {
                    item.items.map((values: ItemsProps) => (
                      <S.AccordeonContent key={values.icon} className='d-flex align-items-center justify-content-between mb-3'>
                        <S.InfoList className='d-flex justify-content-between justify-content-md-start'>
                          <li
                            className='fs-14 lh-17 text-graphite-one mb-0'
                            dangerouslySetInnerHTML={{ __html: values.description }}
                          />
                        </S.InfoList>
                      </S.AccordeonContent>
                    ))
                  }
                </S.AccordeonContent>
              </S.Accordeon>
            </div>
          </Fragment>
        ))
      }
    </>
  )
}

export default ToggleAccordeonImage
