import { device } from 'src/styles/breakpoints'
import { white } from 'src/styles/colors'
import styled from 'styled-components'

export const ImageAccordion = styled.div`
   position: relative;
   margin-bottom: 10px;

  @media ${device.tablet} {
    position: absolute;
    margin-top: 110px;
  }
  @media ${device.desktopXL} {
    margin-top: 125px;
  }
 
  img {
    @media ${device.desktopXL} {
      float: left;
    }
    @media ${device.desktopXXXL} {
      float: none;
    }
    @media ${device.desktopXXLL} {
      right: 100px;
      position: relative;
    }
  }
`

export const Accordeon = styled.div`
  background: ${white};
  max-height: 49px;
  padding: 8px 15px;
  transition: max-height 0.25s;
  overflow: hidden;
  border-radius: 16px;
  align-items: center;
  display: flex;
  flex-direction: column;
  max-width: 312px;
  margin: 8px auto;

  @media ${device.tablet} {
    margin-top: 0;
  }
  @media ${device.desktopLG} {
    max-width: 455px;
    max-height: 56px;
    margin-bottom: 0;
  }
  @media ${device.desktopXL} {
    max-width: 455px;
    max-height: 56px;
    margin-bottom: 8px;
  }
  @media ${device.desktopXL} {
    max-height: 72px;
  }

  &.active {
    max-height: 1000px;
    transition: max-height 0.25s ease-in;
    overflow: visible;
  }
`

export const AccordeonTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media ${device.desktopLG} {
    margin-top: 5px;
  }
  @media ${device.desktopXL} {
    margin-top: 12px;
  }

  div {
    cursor: pointer;
    height: 32px;

    &.arrow-active {
      transform: rotate(180deg);
      transition: 0.5s;
    }
  }
`

export const AccordeonContent = styled.div`
  max-width: 600px;
`

export const InfoList = styled.ul`
  width: 100%;
  margin-bottom: 0;
  list-style: none;

  li {

    @media ${device.desktopXL} {
      margin-left: 10px;
    }
  }
`
