import { device } from 'src/styles/breakpoints'
import { grayscale, orange, white } from 'src/styles/colors'
import { graphiteOne } from 'src/styles/newColors'
import styled from 'styled-components'

import { DataImageCarousel } from './types'

export const MainBanner = styled.section`
  display: flex;
  width: 100%;
  min-height: 734px;

  @media ${device.tablet} { 
    min-height: 470px;
  }
  @media ${device.desktopLG} { 
    height: 600px;
  }
  @media ${device.desktopXL} { 
    height: 815px;
  }
  @media ${device.desktopXXXL} { 
    height: 900px;
  }
  
  .slider {
    position: relative;
    z-index: 1;
    overflow: hidden;
    width: 100%;
    display: flex;
  }
`

export const CarouselSection = styled.div`
  z-index: 2;
  position: sticky;
  left: 0;
  right: 0;
  width: 100%;
`

export const ImageBanner = styled.div<DataImageCarousel>`
  min-width: 100%;
  height: 360px;
  background-image: url(${(props: DataImageCarousel) => props.bgImage.sm});
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  scroll-snap-type: x mandatory;
  
  &.carousel-item {
    flex: 0 0 auto;
    width: 100%;
    scroll-snap-align: start;
    
    &.active {
      transform: scale(1.05); 
      transition: transform 400ms ease-in-out;
      transform: translate3D(0, 0, 0);
    }
  }

  @media ${device.tablet} { 
    background-image: url(${(props: DataImageCarousel) => props.bgImage.md});
    height: 470px;
    background-size: cover;
    background-position: center center;
    align-items: center;
    display: flex;
  }
  @media ${device.desktopLG} { 
    background-image: url(${(props: DataImageCarousel) => props.bgImage.lg || props.bgImage.md});
    height: 600px;
  }
  @media ${device.desktopXL} { 
    background-image: url(${(props: DataImageCarousel) => props.bgImage.xl || props.bgImage.lg || props.bgImage.md});
    height: 815px;
  }
  @media ${device.desktopXXXL} { 
    background-image: url(${(props: DataImageCarousel) => props.bgImage.xxxl || props.bgImage.xl || props.bgImage.lg || props.bgImage.md});
    height: 900px;
  }
`

export const Nav = styled.nav`
  display: flex;
  overflow-y: hidden;

  @media ${device.desktopLG} { 
    overflow: visible;
  }
  
  button {
    background: ${grayscale[100]};
    border-radius: 20px;
    padding: 5px 16px;
    font-size: 16px;
    line-height: 20px;
    color: ${grayscale[500]};
    height: 40px;
    margin-right: 8px;
    width: auto;
    white-space: nowrap;
    word-break: normal;

    @media ${device.tablet} { 
      margin-right: 16px;
    }

    &.active {
      background: ${graphiteOne};
      font-weight: 600;
      color: ${white};
    }
  }
`

export const StepCard = styled.div`
  background: ${white};
  border-radius: 20px 20px 0 0;
  padding: 40px 24px;
  width: 100%;
  min-height: 390px;
  left: 0;
  position: relative;
  z-index: 3;
  top: 330px;

  @media ${device.tablet} { 
    width: 456px;
    min-height: 339px;
    border-radius: 16px;
    padding: 32px;
    left: -280px;
    top: 55px;
  }
  @media ${device.desktopLG} { 
    width: 536px;
    left: -430px;
    top: 95px;
  }
  @media ${device.desktopXL} { 
    width: 580px;
    left: -640px;
    top: 180px;
  }
  @media ${device.desktopXXL} { 
    left: -740px;
  }
  @media ${device.desktopXXXL} { 
    left: -900px;
    top: 240px;
  }
  @media ${device.desktopXXLL} { 
    left: -1050px;
  }

  p {
    min-height: 90px;

    @media ${device.tablet} { 
      min-height: 95px;
    }
  }

  button {

    &.modal-button {
      max-width: 100%;
      background: ${orange[500]};
    }
  }
`
