import React from 'react'
import FAQ from 'src/components/Faq'

import pageContext from '../../pageContext.json'
import { Section } from './style'

const FaqInterOne = () => {
    return (
      <Section id='faq-inter-one'>
        <div className='container'>
          <div className='row'>
            <div className='mb-4'>
              <h2 className='fs-24 lh-30 fs-xl-40 lh-xl-50 text-white mb-4 pb-2'>
                Perguntas frequentes
              </h2>
              <div className='row'>
                <FAQ
                  questionColor='text-white'
                  answersColor='text-white'
                  answerData={pageContext.structuredData.faq}
                  columns={{ xl: 1, lg: 1 }}
                />
              </div>
            </div>
          </div>
        </div>
      </Section>
    )
}

export default FaqInterOne
