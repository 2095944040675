import { device } from "src/styles/breakpoints"
import { gray } from "src/styles/newColors"
import styled from "styled-components"

export const SectionCartaoMasterCard = styled.section`
  min-height: 932px;
  background: ${gray};
  display: flex;
  position: relative;
  padding:  35px 0 40px 0;

  @media ${device.tablet} {
    min-height: 614px;
    align-items: center;
  }
  @media ${device.desktopLG} {
    min-height: 657px;
  }
  @media ${device.desktopXL} {
    min-height: 837px;
  }
`
