import React from 'react'
import BlogCard from 'src/components/BlogCard/_BlogCard'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import blogCardsInfo from './blog-cards-info.json'

import { BlogCardT } from 'src/components/BlogCard/_types'
import { SectionSerOneSerUnico } from './styles'

const SerOneEserUnico = () => {
  const [ sendDataLayer ] = useDataLayer()

  return (
    <SectionSerOneSerUnico id='ser-one-e-ser-unico'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 text-center'>
            <h2 className='font-citrina text-white fs-24 lh-28 fs-md-40 lh-md-44 fs-xl-48 lh-xl-52 fw-600 mb-4'>
              Ser One é ser único
            </h2>
            <p className='text-white fs-14 lh-17 fs-md-16 lh-md-20 fs-xl-18 lh-xl-22 mb-4 px-3'>
              Fique por dentro das soluções pensadas para você
            </p>
          </div>
          <div className='col-12 content_wrapper'>
            <article className='content_wrapper__blog_container mx-auto'>
              {blogCardsInfo.map((card: BlogCardT) => (
                <BlogCard
                  key={card.id}
                  card={card}
                  showImage
                  isMobile
                  onCardClick={() => sendDataLayer({
                    section: 'dobra_06',
                    section_name: 'Ser One é ser único: Fique por dentro das soluções pensadas para você',
                    element_action: 'click button',
                    element_name: card.title,
                    redirect_url: card.link,
                  })}
                />
              ))}
            </article>
          </div>
        </div>
      </div>
    </SectionSerOneSerUnico>
  )
}

export default SerOneEserUnico
