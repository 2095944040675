import React from 'react'
import ToggleAccordeonImage from 'src/components/ToggleAccordeon/image'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import dataProps from './../../assets/data/inter-one-voce-pode-mais.json'

import { SectionVocePodeMais } from './styles'

const ComInterVocePodeMais = ({ setOpenModal, setDobra }: InterOneProps) => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const data = dataProps

  return (
    <SectionVocePodeMais id='com-o-inter-voce-pode-mais'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 text-center mb-xl-3'>
            <h2 className='font-citrina text-white fs-24 lh-28 fs-md-28 lh-md-34 fs-lg-40 lh-lg-44 fs-xl-48 lh-xl-52 fw-600 mb-3'>
              <span className='d-block d-md-inline'>Com Inter One você</span> pode mais
            </h2>
            <p className='text-white fs-16 lh-20 fs-xl-18 lh-xl-22 mb-4'>
              Conheça as vantagens personalizadas que ser Inter One te proporciona
            </p>
          </div>
          <ToggleAccordeonImage
            infoData={data}
            section='dobra_02'
            sectionName='Com Inter One você pode mais'
          />
          <div className='col-12 col-md-6 offset-md-6 text-center'>
            <button
              title='Quero ser Inter One'
              className='btn btn--lg bg-orange--base text-white text-none mt-md-2 mt-lg-3'
              onClick={() => {
                setOpenModal && setOpenModal(true)
                setDobra('dobra_02')
                sendDatalayerEvent({
                  section: 'dobra_02',
                  section_name: 'Com Inter One você pode mais',
                  element_action: 'click button',
                  element_name: 'Quero ser Inter One',
                })
              }}
            >
              Quero ser Inter One
            </button>
          </div>
        </div>
      </div>
    </SectionVocePodeMais>
  )
}

export default ComInterVocePodeMais
