import React from "react"
import OpenVideo from 'src/components/OpenVideo'
import useDataLayer from "src/hooks/useDataLayer/dataLayerBody"

import useWidth from "src/hooks/window/useWidth"
import { WIDTH_MD } from 'src/styles/breakpoints'

import PlayVideoOrange from 'src/assets/images/shared/play-video-orange.png'
import { ContainerVideo, MainVideoInterOne, PlayVideoInterOne } from "./styles"

type WebmContainerVideoProps = {
  video: string;
  urlVideo: string;
}

const WebmContainerVideo = ({ video, urlVideo }: WebmContainerVideoProps) => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const width = useWidth(300)

  return (
    <ContainerVideo>
      <video
        key='introVideo'
        loop
        muted
        autoPlay
      >
        <source
          id='videoWebm'
          type='video/webm'
          src={video}
        />
      </video>
      <OpenVideo
        link={urlVideo}
        onClick={() => {
          sendDatalayerEvent({
            section: 'dobra_05',
            section_name: 'Inter One você na frente',
            element_action: 'click video',
            element_name: 'Assista ao vídeo',
            redirect_url: urlVideo,
          })
        }}
      >
        <MainVideoInterOne>
          <PlayVideoInterOne>
            <img
              src={PlayVideoOrange}
              alt='Assistir ao vídeo'
              width={ width < WIDTH_MD ? 65 : 85 }
              height={ width < WIDTH_MD ? 96 : 126}
            />
          </PlayVideoInterOne>
        </MainVideoInterOne>
      </OpenVideo>
    </ContainerVideo>
  )
}

export default WebmContainerVideo
