import React from 'react'
import IconsSwitch from 'src/components/IconsSwitch/_index'
import ImageWebp from 'src/components/ImageWebp'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_MD } from 'src/utils/breakpoints'

import { WIDTH_LG } from 'src/styles/breakpoints'
import { SectionCartaoMasterCard } from './styles'

const CartaoMastercardPlatinum = ({ setOpenModal, setDobra }: InterOneProps) => {
  const width = useWidth()
  const [ sendDatalayerEvent ] = useDataLayer()
  let imageCartao = 'https://central-imagens.bancointer.com.br/images-without-small-versions/one-section-4-cartoes-mobile/image.webp'

  if (width >= WIDTH_MD && width < WIDTH_LG) {
    imageCartao = 'https://central-imagens.bancointer.com.br/images-without-small-versions/one-section-4-cartoes-small/image.webp'
  } else if (width >= WIDTH_LG) {
    imageCartao = 'https://central-imagens.bancointer.com.br/images-without-small-versions/one-section-4-cartoes-desktop/image.webp'
  }

  const listOfBenefits = [
    {
      icon: "ic_credit_card",
      customPath: "finance/",
      description: "Mastercard Surpreenda",
    },
    {
      icon: "ic_plane_departure",
      customPath: "travel-mobility/",
      description: "Mastercard Airport Concierge",
    },
    {
      icon: "ic_luggage",
      customPath: "travel-mobility/",
      description: "Mastercard Travel Rewards",
    },
    {
      icon: "ic_globe",
      customPath: "home-objects/",
      description: "Assistência Global de Emergência",
    },
    {
      icon: "ic_add",
      customPath: "action-navigation/",
      description: "E muito mais",
    },
  ]

  return (
    <SectionCartaoMasterCard id='cartao-mastercard-platinum'>
      <div className='container'>
        <div className='row align-items-md-center'>
          <div className='col-12 col-md-6 col-xl-7'>
            <ImageWebp
              pathSrc={imageCartao}
              altDescription='Cartão Mastercard Platinum'
              arrayNumbers={[ 320, 349, 477, 633, 633 ]}
              arrayNumbersHeight={[ 370, 439, 530, 660, 660 ]}
              className='mb-4 mb-md-0'
            />
          </div>
          <div className='col-12 col-md-6 col-xl-5 pr-lg-0'>
            <h2 className='font-citrina text-grayscale--500 fs-24 lh-28 fs-md-28 lh-md-34 fs-lg-40 lh-lg-44 fs-xl-48 lh-xl-52 fw-600 mb-3'>
              <span className='d-xl-block'>Cartão Mastercard</span> Platinum
            </h2>
            <h3 className='font-citrina text-graphite fs-16 lh-20 fs-md-18 lh-md-22 fs-lg-20 lh-lg-24 fs-xl-24 lh-xl-28 mb-4 fw-600 pr-xl-5'>
              Internacional, sem anuidade e com mais benefícios para você.
            </h3>
            <p className='text-grayscale--400 fs-14 lh-17 fs-md-16 lh-md-20 fs-xl-18 lh-xl-22 mb-4'>
              Com o Inter Loop, você acumula 1 ponto a cada R$5,00 em compras no cartão de crédito para
              trocar por vantagens que você escolher. E o melhor: com sua recorrência, seus pontos
              não expiram.
            </p>
            <h3 className='fs-16 lh-20 fs-md-20 lh-md-25 text-primary--500'>
              Benefícios Mastercard
            </h3>
            {listOfBenefits.map((benefit: ListOfBenefitsProps, index: number) => (
              <div key={index} className='d-flex align-items-center justify-content-start mb-2'>
                <div className='mr-3'>
                  <IconsSwitch
                    icon={benefit.icon}
                    color='#616161'
                    lib='interco'
                    size='MD'
                    customPath={benefit.customPath}
                  />
                </div>
                <p className='fs-14 lh-17 fs-md-16 lh-md-20 text-grayscale--500 mb-0'>
                  {benefit.description}
                </p>
              </div>
            ))}
            <button
              title='Evoluir perfil para Inter One'
              className='btn btn--lg bg-orange--base text-white text-none mt-3 mt-md-4'
              onClick={() => {
                setOpenModal && setOpenModal(true)
                setDobra('dobra_04')
                sendDatalayerEvent({
                  section: 'dobra_04',
                  section_name: 'Cartão Mastercard Platinum',
                  element_action: 'click button',
                  element_name: 'Evoluir perfil para Inter One',
                })
              }}
            >
              Evoluir perfil para Inter One
            </button>
          </div>
        </div>
      </div>
    </SectionCartaoMasterCard>
  )
}

export default CartaoMastercardPlatinum
