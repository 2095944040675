import React, { Fragment } from 'react'
import IconsSwitch from 'src/components/IconsSwitch/_index'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import cardJson from './../S7_a-experiencia-certa/_card'
import { CardCarouselExperiences, RadiusBgColor, SectionExperiences } from './style'

const ExperienciaCerta = ({ setOpenModal, setDobra }: InterOneProps) => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const url = 'https://intergo.app/9a5c30c1'

  return (
    <SectionExperiences id='a-experiencia-certa'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h2 className='font-citrina text-grayscale--500 fs-24 lh- lh-md-28 fs-md-34 fs-lg-48 lh-lg-52 fw-600 mb-3 text-center'>
              A experiência certa para você
            </h2>
            <p className='text-grayscale--400 fs-14 lh-17 fs-lg-18 lh-lg-22 text-center mb-0'>
              Descubra os benefícios de cada perfil de relacionamento e encontre o que mais combina com você.
            </p>
          </div>
          <div className='col-12'>
            <DefaultCarousel
              sm={{ items: 1, partialVisibilityGutter: 20 }}
              md={{ items: 2, partialVisibilityGutter: 30 }}
              lg={{ items: 2, partialVisibilityGutter: 90 }}
              xl={{ items: 3, partialVisibilityGutter: 27 }}
            >
              {
              cardJson.map((items: ExperiencesProps, index: number) => (
                <Fragment key={index}>
                  <CardCarouselExperiences image={items.image} role='img' aria-label={items.alt}>
                    <RadiusBgColor bgColor={items.radiusBgColor} />
                    <div className='content-text px-4 mx-auto'>
                      <div>
                        <h3 className='font-citrina text-graphite fs-24 lh-28 lh-md-28 fs-md-34 fs-lg-40 lh-lg-44 fw-600 mb-3'>
                          {items.title}
                        </h3>
                        <p className='text-graphite fs-16 lh-20 fs-lg-18 lh-lg-22 mb-4'>{items.description}</p>
                        {
                          items.listOfExperiences.map((list: ListOfExperiencesProps, index: number) => (
                            <div key={index} className='d-flex align-items-center justify-content-start mb-2'>
                              <div className='mr-3'>
                                <IconsSwitch
                                  icon={list.icon}
                                  color='#616161'
                                  lib='interco'
                                  size='MD'
                                  customPath={list.customPath}
                                />
                              </div>
                              <p className='fs-12 lh-15 fs-lg-14 lh-lg-17 text-grayscale--500 mb-0'>
                                {list.text}
                              </p>
                            </div>
                          ))
                        }
                      </div>
                    </div>
                    <div className='w-100 px-4 mt-4'>
                      {items.link !== ''
                        ? (
                          <a
                            href={items.link}
                            title={items.textButton}
                            className='btn btn--lg text-white text-none'
                            onClick={() => {
                              setDobra('dobra_07')
                              sendDatalayerEvent({
                                section: 'dobra_07',
                                section_name: 'A experiência certa para você',
                                element_action: 'click button',
                                element_name: items.textButton,
                                redirect_url: url,
                              })
                            }}
                          >
                            {items.textButton}
                          </a>)
                        : (
                          <button
                            title={items.textButton}
                            className='btn btn--lg text-white text-none mt-4'
                            onClick={() => {
                              setOpenModal && setOpenModal(true)
                              setDobra('dobra_07')
                              sendDatalayerEvent({
                                section: 'dobra_07',
                                section_name: 'A experiência certa para você',
                                element_action: 'click button',
                                element_name: items.textButton,
                              })
                            }}
                          >
                            {items.textButton}
                          </button>
                        )
                      }
                    </div>
                  </CardCarouselExperiences>
                </Fragment>
              ))}
            </DefaultCarousel>
          </div>
        </div>
      </div>
    </SectionExperiences>
  )
}

export default ExperienciaCerta
