import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import { SectionVoceNaFrente } from './styles'

const HeroVoceNafrente = ({ setOpenModal, setDobra }: InterOneProps) => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <SectionVoceNaFrente
      id='inter-one-voce-na-frente' role='img'
      aria-label='Mulher jovem sentada no sofá, sorrindo ao falar ao telefone'
    >
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-6 col-lg-5'>
            <h1 className='font-citrina text-white fs-24 lh-28 fs-lg-28 lh-lg-34 fs-xl-48 lh-xl-52 fw-600 mb-4 mb-md-5'>
              <span className='d-block fs-48 lh-52 fs-md-56 lh-lg-62 fs-lg-72 lh-lg-80 fs-xl-104 lh-xl-114 mb-3'>Inter One</span> Você na frente
            </h1>
            <p className='font-citrina text-white fs-14 lh-17 fs-lg-18 lh-lg-22 fs-xl-20 lh-xl-24 mb-4 pr-lg-5'>
              <span className='d-xl-block'>Atendimento e benefícios personalizados,</span> pensados para você.
            </p>
            <button
              title='Quero ser Inter One'
              className='btn btn--lg bg-orange--base text-white text-none mt-md-3'
              onClick={() => {
                setOpenModal && setOpenModal(true)
                setDobra('dobra_01')
                sendDatalayerEvent({
                  section: 'dobra_01',
                  section_name: 'Inter One: você na frente',
                  element_action: 'click button',
                  element_name: 'Quero ser Inter One',
                })
              }}
            >
              Quero ser Inter One
            </button>
          </div>
        </div>
      </div>
    </SectionVoceNaFrente>
  )
}

export default HeroVoceNafrente
