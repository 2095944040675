import React, { useState } from 'react'
import { Modal } from 'src/components/Modal'
import useDomReady from 'src/hooks/window/useDomReady'
import Layout from 'src/layouts/BaseLayout'

import InterOneContactForm from 'src/components/UI/Forms/InterOneContactForm'

import PageQuery from './pageContext.json'

import S1_HeroVoceNafrente from './sections/S1_hero-voce-na-frente/_index'
import S2_ComInterVocePodeMais from './sections/S2_com-o-inter-voce-pode-mais/_index'
import S3_ComoSeTornarCliente from './sections/S3_como-se-tornar-cliente/_index'
import S4_CartaoMastercardPlatinum from './sections/S4_cartao-mastercard-platinum/_index'
import S5_InterOnePorQuemMaisEntende from './sections/S5_inter-one-por-quem-mais-entende/_index'
import S6_SerOneEserUnico from './sections/S6_ser-one-e-ser-unico/_index'
import S7_ExperienciaCerta from './sections/S7_a-experiencia-certa/_index'
import S8_QuerSaberMaisSobre from './sections/S8_quer-saber-mais-sobre/_index'
import S9_FaqInterOne from './sections/S9_faq-inter-one/_index'

const InterOne = () => {
  const [ openModal, setOpenModal ] = useState(false)
  const [ dobra, setDobra ] = useState('dobra_1')

  const domReady = useDomReady()

  const modalOpen = domReady && (
    <Modal isModalOpen={openModal} setIsModalOpen={setOpenModal} locationToRender={document.body}>
      <InterOneContactForm closeModal={() => setOpenModal(false)} dobra={dobra} />
    </Modal>
  )

  return (
    <Layout pageContext={PageQuery}>
      {modalOpen}
      <S1_HeroVoceNafrente setOpenModal={setOpenModal} setDobra={setDobra} />
      <S2_ComInterVocePodeMais setOpenModal={setOpenModal} setDobra={setDobra} />
      <S3_ComoSeTornarCliente setOpenModal={setOpenModal} setDobra={setDobra} />
      <S4_CartaoMastercardPlatinum setOpenModal={setOpenModal} setDobra={setDobra} />
      <S5_InterOnePorQuemMaisEntende setOpenModal={setOpenModal} setDobra={setDobra} />
      <S6_SerOneEserUnico />
      <S7_ExperienciaCerta setOpenModal={setOpenModal} setDobra={setDobra} />
      <S8_QuerSaberMaisSobre />
      <S9_FaqInterOne />
    </Layout>
  )
}

export default InterOne
