export default [
  {
    image: 'https://central-imagens.bancointer.com.br/images-without-small-versions/one-dobra7-1/image.webp',
    alt: 'Homem sorrindo enquanto segura um celular e uma caneca',
    radiusBgColor: '#EA7100',
    title: 'Inter Digital',
    description: 'Gratuita, simples e completa',
    link: 'https://inter.co/pra-voce/conta-digital/pessoa-fisica/',
    textButton: 'Saiba mais',
    listOfExperiences: [
      {
        icon: 'ic_credit_card',
        customPath: 'finance/',
        text: 'Cartão Mastercard Gold sem anuidade',
      },
      {
        icon: 'ic_smartphone',
        customPath: 'media-communication/',
        text: 'Atendimento 24h via chat',
      },
      {
        icon: 'ic_inter_loop',
        customPath: 'brands/',
        text: '1 ponto a cada R$10,00 na fatura',
      },
      {
        icon: 'ic_coin',
        customPath: 'finance/',
        text: 'Pix ilimitados e gratuitos',
      },
    ],
  },
  {
    image: 'https://central-imagens.bancointer.com.br/images-without-small-versions/one-dobra7-2/image.webp',
    alt: 'Mulher jovem sentada no sofá, sorrindo ao falar ao telefone',
    radiusBgColor: '#616161',
    title: 'Inter One',
    description: 'Pensado para você',
    link: '',
    textButton: 'Quero ser Inter One',
    listOfExperiences: [
      {
        icon: 'ic_credit_card',
        customPath: 'finance/',
        text: 'Cartão Mastercard Platinum sem anuidade',
      },
      {
        icon: 'ic_heart',
        customPath: 'action-navigation/',
        text: 'Atendimento personalizado',
      },
      {
        icon: 'ic_inter_loop',
        customPath: 'brands/',
        text: '1 ponto a cada R$5,00 na fatura',
      },
      {
        icon: 'ic_handshake',
        customPath: 'human/',
        text: 'Advisor Inter One',
      },
      {
        icon: 'ic_hand_money',
        customPath: 'human/',
        text: 'Assessoria de investimentos',
      },
      {
        icon: 'ic_padlock',
        customPath: 'privacy-security/',
        text: 'Área exclusiva e ofertas especiais',
      },
    ],
  },
  {
    image: 'https://central-imagens.bancointer.com.br/images-without-small-versions/one-dobra7-3/image.webp',
    alt: 'Homem dentro de um carro, estendendo o braço e mostrando seu Cartão Mastercard Black',
    radiusBgColor: '#161616',
    title: 'Inter Black',
    description: 'Experimente a exclusividade',
    link: 'https://inter.co/pra-voce/relacionamento/inter-black/',
    textButton: 'Quero ser Inter Black',
    listOfExperiences: [
      {
        icon: 'ic_credit_card',
        customPath: 'finance/',
        text: 'Cartão Mastercard Black sem anuidade',
      },
      {
        icon: 'ic_desktop_user',
        customPath: 'media-communication/',
        text: 'Atendimento exclusivo via Whatsapp e videochamada',
      },
      {
        icon: 'ic_inter_loop',
        customPath: 'brands/',
        text: '1 ponto a cada R$2,50 na fatura',
      },
      {
        icon: 'ic_handshake',
        customPath: 'human/',
        text: 'Assessoria de investimentos',
      },
      {
        icon: 'ic_padlock',
        customPath: 'privacy-security/',
        text: 'Área exclusiva e ofertas especiais',
      },
      {
        icon: 'ic_star',
        customPath: 'action-navigation/',
        text: 'Acesso a salas VIP',
      },
      {
        icon: 'ic_fork_knife',
        customPath: 'home-objects/',
        text: 'Duo Gourmet gratuito',
      },
    ],
  },
  {
    image: 'https://central-imagens.bancointer.com.br/images-without-small-versions/one-dobra7-4/image.webp',
    alt: 'Mulher sorrindo e segurando um celular em suas mãos.',
    radiusBgColor: '#323747',
    title: 'Inter Win',
    description: 'Gestão patrimonial singular',
    link: 'https://inter.co/pra-voce/relacionamento/inter-win/',
    textButton: 'Quero ser Inter Win',
    listOfExperiences: [
      {
        icon: 'ic_credit_card',
        customPath: 'finance/',
        text: 'Cartão Mastercard Win sem anuidade',
      },
      {
        icon: 'ic_desktop_user',
        customPath: 'media-communication/',
        text: 'Atendimento exclusivo via Whatsapp e videochamada',
      },
      {
        icon: 'ic_inter_loop',
        customPath: 'brands/',
        text: '1 ponto a cada R$2,00 na fatura',
      },
      {
        icon: 'ic_handshake',
        customPath: 'human/',
        text: 'Banker dedicado a você',
      },
      {
        icon: 'ic_house_check',
        customPath: 'finance/',
        text: 'Planejamento e gestão patrimonial',
      },
      {
        icon: 'ic_padlock',
        customPath: 'privacy-security/',
        text: 'Área exclusiva e ofertas especiais',
      },
      {
        icon: 'ic_star',
        customPath: 'action-navigation/',
        text: 'Sala VIP ilimitada',
      },
      {
        icon: 'ic_fork_knife',
        customPath: 'home-objects/',
        text: 'Duo Gourmet gratuito',
      },
      {
        icon: 'ic_trending_up',
        customPath: 'finance/',
        text: 'Condições exclusivas de investimentos',
      },
      {
        icon: 'ic_globe',
        customPath: 'home-objects/',
        text: 'Taxas diferenciadas de câmbio na Conta Global',
      },
    ],
  },
]
