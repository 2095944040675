import { device } from "src/styles/breakpoints"
import { gray } from "src/styles/newColors"
import styled from "styled-components"

export const SectionPorQuemMaisEntende = styled.section`
  min-height: 1132px;
  background: white;
  padding-bottom: 40px;

  @media ${device.tablet} {
    min-height: 769px;
    padding: 0;
  }
  @media ${device.desktopLG} {
    min-height: 951px;
  }
  @media ${device.desktopXL} {
    min-height: 1023px;
  }
  
  button, a {
    max-width: 100%;
    
    @media ${device.desktopXL} {
      max-width: 466px;
    }
  }
`

export const BoxGrayClient = styled.div`
  background: ${gray};
  border-radius: 8px;
  padding: 16px 24px;
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 18px;
  }

  @media ${device.desktopLG} {
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 24px;
    }
  }
  @media ${device.desktopXL} {
    margin-bottom: 16px;
    max-width: 466px;

    &:last-child {
      margin-bottom: 32px;
    }
  }
`
