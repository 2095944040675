import styled from "styled-components"

import { device } from "src/styles/breakpoints"
import { grayscale, white } from "src/styles/colors"
import { graphiteOne, primary } from "src/styles/newColors"

export const SectionSerOneSerUnico = styled.section`
  min-height: 1350px;
  background-color: ${graphiteOne};
  padding: 40px 0;
  
  @media ${device.tablet} { 
    min-height: 640px;
  }
  @media ${device.desktopLG} {
    min-height: 742px;
    padding-top: 64px;
  }
  @media ${device.desktopXL} {
    min-height: 818px;
    padding-top: 96px;
  }

  .content_wrapper {
    margin: 0;
    padding: 0;

    article {
      a {
        background: none;
      }
    }

    &__title {
      font-family: 'Citrina', sans-serif;
      font-weight: 500;
      font-size: 24px;
      line-height: 28.8px;
      color: ${grayscale[500]};
      margin-bottom: 32px;
      text-align: center;
      
      @media ${device.tablet}{
        margin-bottom: 20px;
      }
      @media ${device.desktopLG}{
        font-size: 28px;
        line-height: 33.6px;
      }
      @media ${device.desktopXXL}{
        font-size: 48px;
        line-height: 52.8px;
        margin-bottom: 28px;
      }
    }
    
    &__blog_container {
      display: flex;
      flex-direction: column;

      @media ${device.tablet}{
        flex-direction: row;
      }

      .card_wrapper {
        max-width: 400px;
        margin: 12px auto;

        @media ${device.tablet}{
          width: 30%;
          min-height: fit-content;
        }
        @media ${device.desktopXXL}{
          width: 31%;
        }

        &__categories_header {
          padding-top: 5px;
          padding-bottom: 5px;
        }

        &__image_wrapper {
          @media ${device.tablet} {
            height: auto;
           
            img {
              border-radius: 16px 16px 0 0;
            }
          }
        }

        &__title_wrapper {
          background-color: ${white};
          min-height: 100px;

          @media ${device.tablet} {
            min-height: 150px;
          }
          @media ${device.desktopLG} {
            min-height: 120px;
          }
        }
        &__text_wrapper {
          background-color: ${white};

          @media ${device.desktopLG} {
            min-height: 180px;
          }
          @media ${device.desktopXL} {
            min-height: 160px;
          }
          
          &__description {
            @media ${device.desktopLG} {
              min-height: 130px;
            }
            @media ${device.desktopXL} {
              min-height: 100px;
            }
          }
        }
        &__button_wrapper {
          &__read_more{
            color: ${primary[500]};
          }
        }
      }
    }
  }
`
