import React from 'react'
import CarouselSectionBanner from 'src/components/UI/Carousels/CarouselSectionBanner'
import CarouselData from "./../../assets/data/section-carousel-data.json"

const ComoSeTornarCliente = ({ setOpenModal, setDobra }: InterOneProps) => {
  return (
    <CarouselSectionBanner
      dobra='dobra_03'
      setDobra={setDobra}
      setOpenModal={setOpenModal}
      sectionCarouselData={CarouselData}
    />
  )
}

export default ComoSeTornarCliente
