import { device } from "src/styles/breakpoints"
import styled from "styled-components"

export const ContainerVideo = styled.div`
  width: 100%;
  max-height: 430px;
  object-fit: cover;
  position: relative;
  margin-top: -4px;
  
  @media ${device.tablet} {
    position: absolute;
    overflow: hidden;
    width: 382px;
    max-height: 781px;
    margin-top: 0;
    z-index: 1;
    right: 6px;
  }
  @media ${device.desktopLG} {
    width: 500px;
    max-height: 951px;
    right: 0;
  }
  @media ${device.desktopXL} {
    width: 708px;
    max-height: 1023px;
  }

  video {
    min-width: 100%;
    min-height: 100%;
    width: 100%;
    height: auto;

    @media ${device.tablet} {
      width: 427px;
      height: 788px;
    }
    @media ${device.desktopLG} {
      width: 510px;
      height: 1035px;
    }
    @media ${device.desktopXL} {
      width: 708px;
      height: 1453px;
      right: -3px;
      position: relative;
    }
  }
`

export const MainVideoInterOne = styled.div`
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 10px;

  @media ${device.tablet} {
    top: 0;
  }
`

export const PlayVideoInterOne = styled.div`
  cursor: pointer;
  position: absolute;
  z-index: 10;
`
