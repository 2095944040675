import { device } from 'src/styles/breakpoints'
import { white } from 'src/styles/colors'
import { graphiteOne, primary } from 'src/styles/newColors'
import styled from 'styled-components'

export const SectionSaberMaisSobre = styled.section`
  background: ${graphiteOne};
  padding: 40px 0;
  min-height: 554px;
  align-items: center;
  display: flex;

  @media ${device.tablet} {
    min-height: 405px;
  }
  @media ${device.desktopLG} {
    min-height: 497px;
  }
  @media ${device.desktopXL} {
    min-height: 522px;
  }
`

export const BoxLearnMore = styled.div`
  background: ${white};
  padding: 24px;
  border-radius: 16px;
  min-height: 142px;
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0;
  }

  a, span {
    color: ${primary[500]}
  }
`
